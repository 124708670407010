
    (async () => {
      /* inject correct language here at the very start manually*/
      // cSpell:disable
      const messages = {
        'Connection error': {
          de: 'Verbindungsfehler',
          es: 'Error de conexión',
          fr: 'Erreur de connexion',
          it: 'Errore di connessione',
          ja: '接続エラー',
          nl: 'Verbindingsfout'
        },
        'Error': {
          de: 'Fehler',
          es: 'Error',
          fr: 'Erreur',
          it: 'Errore',
          ja: 'エラー',
          nl: 'Fout'
        },
        'The service is not available right now.': {
          de: 'Der Dienst ist zurzeit nicht verfügbar.',
          es: 'El servicio no está disponible en estos momentos.',
          fr: 'Le service n\'est pas disponible actuellement.',
          it: 'Il servizio non è disponibile in questo momento.',
          ja: '現在サービスを利用できません。',
          nl: 'De dienst is nu niet benaderbaar.'
        },
        'Retry': {
          de: 'Erneut versuchen',
          es: 'Reintentar',
          fr: 'Réessayer',
          it: 'Ritenta',
          ja: '再試行',
          nl: 'Opnieuw proberen'
        },
        'Sign out': {
          de: 'Abmelden',
          es: 'Cerrar sesión',
          fr: 'Se déconnecter',
          it: 'Esci',
          ja: 'ログアウト',
          nl: 'Afmelden'
        },
        'Connection timed out. Please try reloading the page.': {
          de: 'Zeitüberschreitung bei der Verbindung. Bitte laden Sie die Seite erneut.',
          es: 'Conexión caducada. Por favor, pruebe a cargar de nuevo la página.',
          fr: 'Délai de la connexion dépassé. Veuillez recharger la page.',
          it: 'Connessione scaduta. Prova a ricaricare la pagina.',
          ja: '接続がタイムアウトです。ページをもう一度読み込んでください。',
          nl: 'Verbindingsfout (time-out). Probeer de pagina opnieuw te laden.'
        },
        'Reload': {
          de: 'Neu laden',
          es: 'Recargar',
          fr: 'Recharger',
          it: 'Ricarica',
          ja: '再読み込み',
          nl: 'Herladen'
        },
        'Something went wrong. Please close this browser tab and try again.': {
          de: 'Es gab einen Fehler. Bitte schließen Sie dieses Browser-Tab und versuchen es erneut.',
          es: 'Algo ha ido mal. Por favor, cierre esta pestaña del navegador e inténtelo de nuevo.',
          fr: 'Quelque chose s\'est mal passé. Veuillez fermer votre onglet de navigateur et réessayer.',
          it: 'Qualcosa è andato storto. Chiudi questa scheda del browser e prova ancora.',
          ja: 'エラーが発生しました。このブラウザタブを閉じて、もう一度やり直してください。',
          nl: 'Er is iets mis gegaan.Sluit alstublieft dit tabblad en probeer het opnieuw.'
        },
        'Some mandatory configuration could not be loaded.': {
          de: 'Einige wichtige Teile der Konfiguration konnten nicht geladen werden.',
          // google translate for now (some translation is better than no translation)
          es: 'No se pudo cargar alguna configuración obligatoria.',
          fr: 'Certaines configurations obligatoires n\'ont pas pu être chargées.',
          it: 'Impossibile caricare alcune configurazioni obbligatorie.',
          ja: '一部の必須構成をロードできませんでした。',
          nl: 'Bepaalde verplichte configuraties konden niet worden geladen.'
        }
      }
      // cSpell:enable

      let language
      const staticGt = (str) => {
        if (!messages[str]) return str;
        return messages[str][language] || str;
      }

      function getLanguage() {
        // try url and cookie first
        const match = location.hash.match(/language=(\w+)/) || document.cookie.match(/locale=(\w+)/)
        return (match?.[1] || document.documentElement.lang || navigator.language || navigator.userLanguage).substr(0, 2)
      }

      function getMessage(title, message, retry = true) {
        return '<img src="./themes/default/illustrations/error-generic.svg" alt="">' +
          `<h1>${title}</h1>` +
          `<div>${message}</div><div class="details text-sm opacity-75"></div>` +
          '<div class="actions">' +
          '<button type="button" class="signout btn btn-default">' + staticGt('Sign out') + '</button>' +
          (retry ? '<button type="button" class="reload btn btn-primary">' + staticGt('Retry') + '</button>' : '') +
          '</div>'
      }

      function updateMessages() {
        language = getLanguage()
        // needed if someone called ox.idle (may happen a lot on mobile)
        if (!document.querySelector('#showstopper')) return
        document.querySelector('#showstopper .down').innerHTML = getMessage(staticGt('Connection error'), staticGt('The service is not available right now.'))
        document.querySelector('#showstopper .timeout').innerHTML = getMessage(staticGt('Connection error'), staticGt('Connection timed out. Please try reloading the page.'))
        document.querySelector('#showstopper .configuration').innerHTML = getMessage(staticGt('Error'), staticGt('Some mandatory configuration could not be loaded.'))
        document.querySelector('#showstopper .session').innerHTML = getMessage(staticGt('Error'), staticGt('Something went wrong. Please close this browser tab and try again.'), false)
      }

      updateMessages()
      document.documentElement.addEventListener('languageChange', updateMessages);
    })()
  